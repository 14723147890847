<template>
  <div class="corporateCulture">
      <Head @closeMenu="closeOpenMenu" />
       <Menu ref="menu" />
        <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
      <div class="banner">
                <!-- <div class="forward">
          </div> -->
      </div>
      <div class="contents" id="contents">
          <div class="contentCenter">
              <h3 class="title" id="title">企业文化</h3>
              <p class="lines">
                <span class="lineCenter"></span>
              </p>

              <div class="contextCenter">
                <div class="left"></div>
                <div class="right">
                    <h2 class="titles">愿景</h2>
                    <p class="textContent">做一家让我们骄傲的公司</p>
                    <p class="EnIntroduce">To make a company that we are proud of.</p>

                     <h2 class="titles" style="margin-top: 26px;">使命</h2>
                    <p class="textContent">成为行业数字化转型的推动者</p>
                    <p class="EnIntroduce"> To be a promoter of the industry's digital transformation. </p>


                     <h2 class="titles" style="margin-top: 26px;">理念</h2>
                    <p class="textContent">以客户为中心，以奋斗者为本，以价值为纲</p>
                    <p class="EnIntroduce">Customer-centered, striver-oriented, value-guided.</p>


                     <h2 class="titles" style="margin-top: 26px;">宗旨</h2>
                    <p class="textContent">真诚 、专业、创新、协作</p>
                    <p class="EnIntroduce">Sincere, professional, innovative cooperative</p>
                    <div class="EnName"></div>
                </div>
              </div>
          </div>
      </div>
        <Footer />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
import setScrollTop from "../../utils/setScrollTop.js";
export default {
 components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
    methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
   watch: {
    $route(to, from) {
      setScrollTop('title');
    },
  },
  mounted(){
    setScrollTop('title');
  },

}

</script>

<style scoped>
/* banner图开始 */
 .banner{
     width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .banner .forward{
    width: 164px;
    height: 44px;
    background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
     background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 58px;
    bottom: 26px;
 }
 /* banner图结束 */

 /* 主体内容开始 */
 .contents{
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/backgroundImage.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .contents .contentCenter{
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: 0 auto;
 }
 .contents .contentCenter .title{
   font-size: 40px;
   font-family: IOS9W4;
   font-weight: bold;
   color: #17171C;
 }
 .contents .contentCenter .lines{
   margin-bottom: 36px;
 }
 .contents .contentCenter .lines .lineCenter{
    width: 70px;
    height: 4px;
    background: #3C4047;
    border-radius: 2px;
    display: inline-block;
 }
 .contents .contentCenter .contextCenter{
    color: #333333;
    display: flex;
    padding-top: 37px;
    justify-content: space-between;
 }
 .contents .contentCenter .contextCenter .left{
    width: 577px;
    text-align: left;
    display: inline-block;
    background-image: url("../../assets/image/aboutGroup/corporate/content.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: Microsoft YaHei;
    overflow: hidden;
    height: 796px;
 }
 .contents .contentCenter .contextCenter .right{
    width: 850px;
    display: inline-block;
    text-align: left;
 }
 .contents .contentCenter .contextCenter .right .titles{
   font-size: 50px;
   /* height: 36px; */
   font-family: IOS9W4;
   font-weight: bold;
   color: #6C35E4;
 }
  .contents .contentCenter .contextCenter .right .textContent{
    padding-top: 18px;
    font-size: 34px;
    height: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .contents .contentCenter .contextCenter .right .EnIntroduce{
      padding-top: 20px;
      font-size: 27px;
      font-family: Arial;
      font-weight: 400;
      color: #666666;
  }
  .contents .contentCenter .contextCenter .right .EnName{
    width: 739px;
    height: 58px;
    background-image: url("../../assets/image/aboutGroup/corporate/EnName.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* margin: 0 auto; */
    margin-top: 12px;
  }

  /* hover 事件 */
 .contents .contentCenter .contextCenter .left:hover {
    background-size: 105% 105%;
    transition: 0.5s;
}
</style>